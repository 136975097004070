import React, { ReactNode } from 'react';
import { dark, light } from './theme/theme';
import { ThemeManagerProvider } from './theme/theme-manager';
import { StyledThemeProvider } from './theme/styled-theme-provider';

interface GatsbyRootProps {
  element: ReactNode;
}

export const wrapRootElement = (gatsbyRootProps: GatsbyRootProps) => {
  return (
    <ThemeManagerProvider>
      <StyledThemeProvider lightTheme={light} darkTheme={dark}>
        {gatsbyRootProps.element}
      </StyledThemeProvider>
    </ThemeManagerProvider>
  );
};
