export const dark = {
  background: '#000',
  headerBorder: '#181818',
  navLink: '#fff',
  textPrimary: '#fff',
  textSecondary: '#9d9ea8',
  cardBackground: '#181818',
};

export const light = {
  background: '#fff',
  headerBorder: '#E7E9EE',
  navLink: '#000',
  textPrimary: '#141515',
  textSecondary: '#68696e',
  cardBackground: '#fff',
};

export interface ThemeConfigProps {
  dark: Record<string, unknown>;
  light: Record<string, unknown>;
}

export const theme: ThemeConfigProps = {
  dark,
  light,
};
